.SnackbarContent-root {
    box-shadow: none !important;
    border-radius: 0 !important;
    align-items: center;
    flex-wrap: nowrap !important;
}

.SnackbarItem-action {
    padding-left: 5px !important;
    cursor: pointer;
}

.SnackbarItem-contentRoot {
    background-color: var(--COLOR_SECONDARY_NEUTRAL) !important;
    border: solid 1px var(--COLOR_PRIMARY70) !important;
    color: var(--COLOR_PRIMARY) !important;
}

.SnackbarItem-variantSuccess {
    background-color: var(--COLOR_GREEN_BG_FLAT) !important;
    border: solid 1px var(--COLOR_GREEN) !important;
    color: var(--COLOR_GREEN_TEXT) !important;
    stroke: var(--COLOR_GREEN_TEXT) !important;
}



.SnackbarItem-variantInfo {
    background-color: var(--COLOR_BLUE_BG_FLAT) !important;
    border: solid 1px var(--COLOR_BLUE) !important;
    color: var(--COLOR_BLUE_TEXT) !important;
    stroke: var(--COLOR_BLUE_TEXT) !important;
}

.SnackbarItem-variantError {
    background-color: var(--COLOR_RED_BG_FLAT) !important;
    border: solid 1px var(--COLOR_RED) !important;
    color: var(--COLOR_RED_TEXT) !important;
    stroke: var(--COLOR_RED_TEXT) !important;
}

.SnackbarItem-variantWarning {
    background-color: var(--COLOR_YELLOW_BG_FLAT) !important;
    border: solid 1px var(--COLOR_YELLOW) !important;
    color: var(--COLOR_YELLOW_TEXT) !important;
    stroke: var(--COLOR_YELLOW_TEXT) !important;
}