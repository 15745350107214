@font-face {
	font-family: 'Zark Sun';
	src: local('Zark Sun'), local('ZarkSun'), url('/css/fonts/ZarkSun.woff2') format('woff2'), url('/css/fonts/ZarkSun.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Zark Sans';
	src: local('Zark Sans'), local('ZarkSans'), url('/css/fonts/ZarkSans.woff2') format('woff2'), url('/css/fonts/ZarkSans.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
	/* Brand Colors */
	--COLOR_PRIMARY: #333838;
	--COLOR_PRIMARY70: #33383870;
	--COLOR_PRIMARY16: #33383816;
	--COLOR_SECONDARY: #d3b994;
	--COLOR_SECONDARY_LIGHT: #faf0e2;
	--COLOR_SECONDARY_NEUTRAL: #F4F3EF;
	--COLOR_WHITE: #ffffff;
	--COLOR_EVENT: #C35F00;

	/* Grey Colors */
	--COLOR_GREY: #d2d4d5;
	--COLOR_GREY_WARM_LIGHT: #f4f3f0;
	--COLOR_GREY_DARK: #aaaaaa;
	--COLOR_GREY_LIGHT: #f2f2f2;

	/* Semantic Colors */
	--COLOR_RED: #e87a7a;
	--COLOR_RED_BG_FLAT: #ffe3e4;
	--COLOR_RED_BG: #e87a7a20;
	--COLOR_RED_TEXT: #9e1313;
	--COLOR_BLUE: #7ab1e8;
	--COLOR_BLUE_BG_FLAT: #e2effb;
	--COLOR_BLUE_BG: #7ab1e820;
	--COLOR_BLUE_TEXT: #074f97;
	--COLOR_YELLOW: #ddb037;
	--COLOR_YELLOW_BG_FLAT: #faefd4;
	--COLOR_YELLOW_BG: #ddb03720;
	--COLOR_YELLOW_TEXT: #98761a;
	--COLOR_GREEN: #74c98b;
	--COLOR_GREEN_BG_FLAT: #dff5e7;
	--COLOR_GREEN_BG: #74c98b20;
	--COLOR_GREEN_TEXT: #178b36;

	/* Shadows */
	--SHADOW_SOFT: 0px 3px 9px #33383816;
	--SHADOW_ILLUSTRATION: 0px 0px 20px #33383816;
	--SHADOW_PHONE: 20px 20px 20px #33383816;

	/* Heading Type Scale */
	--TYPE_H1: 50px;
	--TYPE_H2: 40px;
	--TYPE_H3: 30px;
	--TYPE_H4: 22px;
	--TYPE_H5: 16px;

	/* Paragraph Type Scale */
	--TYPE_XLP: 34px;
	--TYPE_LP: 22px;
	--TYPE_MP: 18px;
	--TYPE_SP: 12px;
	--TYPE_XSP: 10px;

	/* Label Type Scale */
	--TYPE_ML: 24px;
	--TYPE_SL: 16px;

	/* Decorative Type Scale */
	--TYPE_LD: 55px;
	--TYPE_XLD: 68px;
	--TYPE_XXLD: 86px;
	--TYPE_XXXLD: 108px;

	/* Font Family */
	--FONT_ZARK: 'Zark Sans', 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	--FONT_SUN: 'Zark Sun', 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	--FONT_TEXT: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
}

*,
::after,
::before {
	box-sizing: border-box;
}

html,
body {
	font-family: var(--FONT_TEXT);
	font-weight: 400;
	color: var(--COLOR_PRIMARY);
	scroll-behavior: smooth;
	font-size: var(--TYPE_MP);
	line-height: 1.5em;
	overflow-x: hidden;
}

body {
	position: relative;
}

img {
	user-select: none;
	pointer-events: none;
}

input {
	font-family: var(--FONT_TEXT);
}

button {
	font-family: var(--FONT_TEXT);
}

h1,
h2,
h3,
h4,
h5 {
	font-family: var(--FONT_SUN);
}

h1 {
	font-size: var(--TYPE_H1);
	line-height: 1.25em;
}

h2 {
	font-size: var(--TYPE_H2);
	line-height: 1.1em;
}

h3 {
	font-size: var(--TYPE_H3);
	line-height: 1.1em;
}

h4 {
	font-size: var(--TYPE_H4);
	line-height: 1.1em;
}

h4 {
	font-size: var(--TYPE_H5);
}

p {
	font-size: var(--TYPE_MP);
	line-height: 1.55em;
}

u {
	text-decoration: none;
	display: inline-block;
	border-bottom: 4px solid var(--COLOR_SECONDARY);
}
