@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: block;
}

/* Document
   ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
	line-height: 1.15; /* 1 */
	-webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
     ========================================================================== */

/**
   * Remove the margin in all browsers.
   */

body {
	margin: 0;
}

/**
   * Render the `main` element consistently in IE.
   */

main {
	display: block;
}

/* Text-level semantics
     ========================================================================== */

/**
   * Remove the gray background on active links in IE 10.
   */

a {
	background-color: transparent;
	text-decoration: none;
	color: inherit;
}

/* Embedded content
     ========================================================================== */

/**
   * Remove the border on images inside links in IE 10.
   */

img {
	border-style: none;
}

/* Forms
     ========================================================================== */

/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */

button {
	border: none;
	cursor: pointer;
}

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

/**
   * Remove the inner border and padding in Firefox.
   */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box; /* 1 */
	padding: 0; /* 2 */
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

[type='search'] {
	-webkit-appearance: textfield; /* 1 */
	outline-offset: -2px; /* 2 */
}

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */

::-webkit-file-upload-button {
	-webkit-appearance: button; /* 1 */
	font: inherit; /* 2 */
}

/* Interactive
     ========================================================================== */

/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */

details {
	display: block;
}

/*
   * Add the correct display in all browsers.
   */

summary {
	display: list-item;
}

/* Misc
     ========================================================================== */

/**
   * Add the correct display in IE 10+.
   */

template {
	display: none;
}

/**
   * Add the correct display in IE 10.
   */

[hidden] {
	display: none;
}

@font-face {
	font-family: 'Zark Sun';
	src: local('Zark Sun'), local('ZarkSun'), url('/css/fonts/ZarkSun.woff2') format('woff2'), url('/css/fonts/ZarkSun.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Zark Sans';
	src: local('Zark Sans'), local('ZarkSans'), url('/css/fonts/ZarkSans.woff2') format('woff2'), url('/css/fonts/ZarkSans.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

:root {
	/* Brand Colors */
	--COLOR_PRIMARY: #333838;
	--COLOR_PRIMARY70: #33383870;
	--COLOR_PRIMARY16: #33383816;
	--COLOR_SECONDARY: #d3b994;
	--COLOR_SECONDARY_LIGHT: #faf0e2;
	--COLOR_SECONDARY_NEUTRAL: #F4F3EF;
	--COLOR_WHITE: #ffffff;
	--COLOR_EVENT: #C35F00;

	/* Grey Colors */
	--COLOR_GREY: #d2d4d5;
	--COLOR_GREY_WARM_LIGHT: #f4f3f0;
	--COLOR_GREY_DARK: #aaaaaa;
	--COLOR_GREY_LIGHT: #f2f2f2;

	/* Semantic Colors */
	--COLOR_RED: #e87a7a;
	--COLOR_RED_BG_FLAT: #ffe3e4;
	--COLOR_RED_BG: #e87a7a20;
	--COLOR_RED_TEXT: #9e1313;
	--COLOR_BLUE: #7ab1e8;
	--COLOR_BLUE_BG_FLAT: #e2effb;
	--COLOR_BLUE_BG: #7ab1e820;
	--COLOR_BLUE_TEXT: #074f97;
	--COLOR_YELLOW: #ddb037;
	--COLOR_YELLOW_BG_FLAT: #faefd4;
	--COLOR_YELLOW_BG: #ddb03720;
	--COLOR_YELLOW_TEXT: #98761a;
	--COLOR_GREEN: #74c98b;
	--COLOR_GREEN_BG_FLAT: #dff5e7;
	--COLOR_GREEN_BG: #74c98b20;
	--COLOR_GREEN_TEXT: #178b36;

	/* Shadows */
	--SHADOW_SOFT: 0px 3px 9px #33383816;
	--SHADOW_ILLUSTRATION: 0px 0px 20px #33383816;
	--SHADOW_PHONE: 20px 20px 20px #33383816;

	/* Heading Type Scale */
	--TYPE_H1: 50px;
	--TYPE_H2: 40px;
	--TYPE_H3: 30px;
	--TYPE_H4: 22px;
	--TYPE_H5: 16px;

	/* Paragraph Type Scale */
	--TYPE_XLP: 34px;
	--TYPE_LP: 22px;
	--TYPE_MP: 18px;
	--TYPE_SP: 12px;
	--TYPE_XSP: 10px;

	/* Label Type Scale */
	--TYPE_ML: 24px;
	--TYPE_SL: 16px;

	/* Decorative Type Scale */
	--TYPE_LD: 55px;
	--TYPE_XLD: 68px;
	--TYPE_XXLD: 86px;
	--TYPE_XXXLD: 108px;

	/* Font Family */
	--FONT_ZARK: 'Zark Sans', 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	--FONT_SUN: 'Zark Sun', 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	--FONT_TEXT: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
}

*,
::after,
::before {
	box-sizing: border-box;
}

html,
body {
	font-family: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	font-family: var(--FONT_TEXT);
	font-weight: 400;
	color: #333838;
	color: var(--COLOR_PRIMARY);
	scroll-behavior: smooth;
	font-size: 18px;
	font-size: var(--TYPE_MP);
	line-height: 1.5em;
	overflow-x: hidden;
}

body {
	position: relative;
}

img {
	-webkit-user-select: none;
	        user-select: none;
	pointer-events: none;
}

input {
	font-family: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	font-family: var(--FONT_TEXT);
}

button {
	font-family: 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	font-family: var(--FONT_TEXT);
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Zark Sun', 'Inter', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	font-family: var(--FONT_SUN);
}

h1 {
	font-size: 50px;
	font-size: var(--TYPE_H1);
	line-height: 1.25em;
}

h2 {
	font-size: 40px;
	font-size: var(--TYPE_H2);
	line-height: 1.1em;
}

h3 {
	font-size: 30px;
	font-size: var(--TYPE_H3);
	line-height: 1.1em;
}

h4 {
	font-size: 22px;
	font-size: var(--TYPE_H4);
	line-height: 1.1em;
}

h4 {
	font-size: 16px;
	font-size: var(--TYPE_H5);
}

p {
	font-size: 18px;
	font-size: var(--TYPE_MP);
	line-height: 1.55em;
}

u {
	text-decoration: none;
	display: inline-block;
	border-bottom: 4px solid #d3b994;
	border-bottom: 4px solid var(--COLOR_SECONDARY);
}


.reactEasyCrop_Container {
	position: relative;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	width: 410px;
	background-color: var(--COLOR_WHITE);
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
	border-radius: 100%;
	border: none !important;
	/* width: 410px !important; */
	/* height: 410px !important; */
}

.reactEasyCrop_Image.reactEasyCrop_Contain {
	position: relative;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid::before {
	border: none !important;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid::after {
	border: none !important;
}

.reactEasyCrop_CropArea {
	color: rgb(244 243 240 / 60%) !important;
}

.MuiSlider-thumb {
	width: 20px !important;
	height: 20px !important;
	margin-top: -8px !important;
	/* border: solid 1px #d2d4d5; */
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
	box-shadow: 0px 0px 0px 8px rgb(210, 212, 213, 30%) !important;
}

.MuiSlider-thumb.MuiSlider-active {
	box-shadow: 0px 0px 0px 12px rgb(210, 212, 213, 30%) !important;
}

span.MuiSlider-root {
	color: var(--COLOR_SECONDARY) !important;
	height: 4px !important;
}

.MuiSlider-rail {
	height: 4px !important;
	opacity: 1 !important;
	background-color: var(--COLOR_GREY) !important;
}

.MuiSlider-track {
	height: 4px !important;
}

.SnackbarContent-root {
    box-shadow: none !important;
    border-radius: 0 !important;
    align-items: center;
    flex-wrap: nowrap !important;
}

.SnackbarItem-action {
    padding-left: 5px !important;
    cursor: pointer;
}

.SnackbarItem-contentRoot {
    background-color: var(--COLOR_SECONDARY_NEUTRAL) !important;
    border: solid 1px var(--COLOR_PRIMARY70) !important;
    color: var(--COLOR_PRIMARY) !important;
}

.SnackbarItem-variantSuccess {
    background-color: var(--COLOR_GREEN_BG_FLAT) !important;
    border: solid 1px var(--COLOR_GREEN) !important;
    color: var(--COLOR_GREEN_TEXT) !important;
    stroke: var(--COLOR_GREEN_TEXT) !important;
}



.SnackbarItem-variantInfo {
    background-color: var(--COLOR_BLUE_BG_FLAT) !important;
    border: solid 1px var(--COLOR_BLUE) !important;
    color: var(--COLOR_BLUE_TEXT) !important;
    stroke: var(--COLOR_BLUE_TEXT) !important;
}

.SnackbarItem-variantError {
    background-color: var(--COLOR_RED_BG_FLAT) !important;
    border: solid 1px var(--COLOR_RED) !important;
    color: var(--COLOR_RED_TEXT) !important;
    stroke: var(--COLOR_RED_TEXT) !important;
}

.SnackbarItem-variantWarning {
    background-color: var(--COLOR_YELLOW_BG_FLAT) !important;
    border: solid 1px var(--COLOR_YELLOW) !important;
    color: var(--COLOR_YELLOW_TEXT) !important;
    stroke: var(--COLOR_YELLOW_TEXT) !important;
}
