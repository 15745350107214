@import '~react-easy-crop/react-easy-crop.css';

.reactEasyCrop_Container {
	position: relative;
	height: fit-content;
	width: 410px;
	background-color: var(--COLOR_WHITE);
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
	border-radius: 100%;
	border: none !important;
	/* width: 410px !important; */
	/* height: 410px !important; */
}

.reactEasyCrop_Image.reactEasyCrop_Contain {
	position: relative;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid::before {
	border: none !important;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid::after {
	border: none !important;
}

.reactEasyCrop_CropArea {
	color: rgb(244 243 240 / 60%) !important;
}

.MuiSlider-thumb {
	width: 20px !important;
	height: 20px !important;
	margin-top: -8px !important;
	/* border: solid 1px #d2d4d5; */
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
	box-shadow: 0px 0px 0px 8px rgb(210, 212, 213, 30%) !important;
}

.MuiSlider-thumb.MuiSlider-active {
	box-shadow: 0px 0px 0px 12px rgb(210, 212, 213, 30%) !important;
}

span.MuiSlider-root {
	color: var(--COLOR_SECONDARY) !important;
	height: 4px !important;
}

.MuiSlider-rail {
	height: 4px !important;
	opacity: 1 !important;
	background-color: var(--COLOR_GREY) !important;
}

.MuiSlider-track {
	height: 4px !important;
}
